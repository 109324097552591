import { Outlet, Link } from "react-router-dom";
import styles from "./Layout.module.css";
import Contoso from "../../assets/Contoso.svg";
import { CopyRegular } from "@fluentui/react-icons";
import { Dialog, Stack, TextField } from "@fluentui/react";
import { useContext, useEffect, useState } from "react";
import { HistoryButton, ShareButton } from "../../components/common/Button";
import { AppStateContext } from "../../state/AppProvider";
import { APIConfig, CosmosDBStatus } from "../../api";
import styled from "styled-components";
import { Header } from "./sections/Header";
import { Connection } from "./sections/Connection";
import { Speech } from "./sections/Speech/Speech";
import { InputOutput } from "./sections/InputOutput";
import { OpenAi } from "./sections/OpenAi";
import { Footer } from "./sections/Footer/Footer";
import { readFileSync } from 'fs';
import { Dropdown, DropdownMenuItemType, IDropdownStyles, IDropdownOption } from '@fluentui/react/lib/Dropdown';
import { v1 as uuid } from 'uuid';

const templates = require("../../api/templates.json");

const StyledSettingsPopup = styled.div`
  & .stack {
    align-items: flex-start;
    align-self: stretch;
    display: flex;
    flex: 0 0 auto;
    flex-direction: column;
    gap: 4px;
    position: relative;
    width: 100%;
  }
`;

function getDropDownOptions(val: any): IDropdownOption {
    return {
        key: val.id,
        text: val.name,
        itemType: val.type === "header" ? DropdownMenuItemType.Header : DropdownMenuItemType.Normal
    };
}

const options: IDropdownOption[] = templates.templates.map(getDropDownOptions)

const Layout = () => {
    const params = new URLSearchParams(window.location.search);
    const typename = params.get("typename");
    const id = params.get("id");
    const [isSharePanelOpen, setIsSharePanelOpen] = useState<boolean>(false);
    const [copyClicked, setCopyClicked] = useState<boolean>(false);
    const [copyText, setCopyText] = useState<string>("Copy URL");
    const [shareLabel, setShareLabel] = useState<string | undefined>("Settings");
    const [hideHistoryLabel, setHideHistoryLabel] = useState<string>("Hide chat history");
    const [showHistoryLabel, setShowHistoryLabel] = useState<string>("Show chat history");
    const [templateOptions, setTemplateOptions] = useState<IDropdownOption[]>(options);
    const appStateContext = useContext(AppStateContext)
    const [config, setConfig] = useState<APIConfig>(appStateContext?.state.frontendSettings?.config as APIConfig);
    const [unsavedConfig, setUnsavedConfig] = useState<APIConfig>(config);
    const [refreshkey, setRefreshKey] = useState<string>("refreshkey");
    const [transcriptionrefreshkey, setTranscriptionRefreshKey] = useState<string>("transcriptionrefreshkey");

    const ui = appStateContext?.state.frontendSettings?.ui;

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        const file = event.target.files?.[0];
        if (file) {
            const fileReader = new FileReader();
            fileReader.onload = (event) => {
                const contents = event.target?.result;
                if (appStateContext) appStateContext.dispatch({ type: 'UPDATE_CONFIG_SETTINGS', payload: JSON.parse(contents as string) as APIConfig });
                setConfig(JSON.parse(contents as string));
                setRefreshKey(uuid());
            };
            fileReader.readAsText(file);
        }
    };

    const handleShareClick = () => {
        setIsSharePanelOpen(true);
    };

    const handleTemplateSelected = (key: string) => {
        const templatetoload = templates.templates.filter((obj: any) => {
            return obj.id === key;
        });
        if(unsavedConfig.persist) {templatetoload[0].config.customerid = unsavedConfig.customerid; templatetoload[0].config.accesscode = unsavedConfig.accesscode; templatetoload[0].config.persist = unsavedConfig.persist;}
        if (appStateContext) appStateContext.dispatch({ type: 'UPDATE_CONFIG_SETTINGS', payload: templatetoload[0].config as APIConfig });
        setConfig(templatetoload[0].config);
        setRefreshKey(uuid());
    };

    const handleImportClick = () => {
        const upload: HTMLInputElement = document.createElement('input');
        upload.setAttribute('type', 'file');
        upload.setAttribute('accept', '.json');
        upload.onchange = (e) => { handleFileChange(e as unknown as React.ChangeEvent<HTMLInputElement>) };
        upload.click();
    };

    const handleExportClick = () => {
        const dataStr = 'data:application/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(unsavedConfig));
        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('href', dataStr);
        downloadLink.setAttribute('download', 'bitfractalautoqm.json');
        downloadLink.click();
    };

    const handleSaveClick = () => {
        if (appStateContext) appStateContext.dispatch({ type: 'UPDATE_CONFIG_SETTINGS', payload: { ...unsavedConfig as APIConfig } });
        setConfig({ ...unsavedConfig as APIConfig });
        setRefreshKey(uuid());
        setIsSharePanelOpen(false);        
    };

    const handleDismissClick = () => {
        setIsSharePanelOpen(false);
    };

    const handleSharePanelDismiss = () => {
        setIsSharePanelOpen(false);
        setCopyClicked(false);
        setCopyText("Copy URL");
    };

    const handleCopyClick = () => {
        navigator.clipboard.writeText(window.location.href);
        setCopyClicked(true);
    };

    const handleHistoryClick = () => {
        appStateContext?.dispatch({ type: 'TOGGLE_CHAT_HISTORY' })
    };

    useEffect(() => {
        if (copyClicked) {
            setCopyText("Copied URL");
        }
    }, [copyClicked]);

    useEffect(() => { }, [appStateContext?.state.isCosmosDBAvailable.status]);

    useEffect(() => {
        setConfig(appStateContext?.state.frontendSettings?.config as APIConfig)
        setUnsavedConfig(appStateContext?.state.frontendSettings?.config as APIConfig)
    }, [appStateContext?.state.frontendSettings?.config]);

    //  useEffect(() => { }, [config]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth < 480) {
                setShareLabel(undefined)
                setHideHistoryLabel("Hide history")
                setShowHistoryLabel("Show history")
            } else {
                setShareLabel("Settings")
                setHideHistoryLabel("Hide chat history")
                setShowHistoryLabel("Show chat history")
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div className={styles.layout}>
            {id ? null : (
                <header className={styles.header} role={"banner"}>
                    <Stack horizontal verticalAlign="center" horizontalAlign="space-between">
                        <Stack horizontal verticalAlign="center" >
                            <a href="https://www.bitfractal.com/">
                                <img
                                    src={ui?.logo ? ui.logo : Contoso}
                                    className={styles.headerIcon}
                                    aria-hidden="true"
                                />
                            </a>
                        </Stack>
                        <Link to="/" className={styles.headerTitleContainer}>
                            <h1 className={styles.headerTitle}>{ui?.title}</h1>
                        </Link>
                        <Stack horizontal tokens={{ childrenGap: 4 }} className={styles.shareButtonContainer}>
                            {(appStateContext?.state.isCosmosDBAvailable?.status !== CosmosDBStatus.NotConfigured) &&
                                <HistoryButton onClick={handleHistoryClick} text={appStateContext?.state?.isChatHistoryOpen ? hideHistoryLabel : showHistoryLabel} />
                            }
                            {ui?.show_share_button && <ShareButton onClick={handleShareClick} text={shareLabel} />}
                        </Stack>
                    </Stack>
                </header>
            )}
            <Outlet />
            <Dialog
                key={refreshkey}
                onDismiss={handleSharePanelDismiss}
                hidden={!isSharePanelOpen}
                styles={{
                    main: [{
                        selectors: {
                            ['@media (min-width: 480px)']: {
                                maxWidth: '90%',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '90%',
                                alignitems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '10px',
                                justifyContent: 'center',
                                padding: '10px',
                                position: 'relative',
                                width: '90%',
                            },
                            ['@media (max-width: 480px)']: {
                                maxWidth: '90%',
                                background: "#FFFFFF",
                                boxShadow: "0px 14px 28.8px rgba(0, 0, 0, 0.24), 0px 0px 8px rgba(0, 0, 0, 0.2)",
                                borderRadius: "8px",
                                maxHeight: '90%',
                                alignitems: 'center',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '0px',
                                justifyContent: 'center',
                                padding: '0px',
                                position: 'relative',
                                width: '90%',
                            }
                        }
                    }]
                }}
            >
                <StyledSettingsPopup>
                    <div className="stack">
                        <Header unsavedconfig={unsavedConfig as APIConfig} config={config as APIConfig} onChange={(config) => { setConfig(config); setUnsavedConfig(config); setTranscriptionRefreshKey(uuid());}} title="AUTOQM SIMULATOR SETTINGS" options={templateOptions} onImportClick={handleImportClick} onExportClick={handleExportClick} onTemplateSelected={handleTemplateSelected} />
                        <Connection config={unsavedConfig as APIConfig} onChange={setUnsavedConfig} url={config?.url as string} autoqmurl={config?.autoqmurl as string}  customerid={config?.customerid as string} accesscode={config?.accesscode as string} pipelineid={config?.pipelineid as string}/>
                        <Speech config={unsavedConfig as APIConfig} onChange={setUnsavedConfig} language={config?.language} voice={config?.voice} phraselist={config?.phraselist} />
                        {/* <InputOutput unsavedconfig={unsavedConfig as APIConfig} config={config as APIConfig} onChange={setUnsavedConfig} /> */}
                        {/* <OpenAi key={transcriptionrefreshkey} unsavedconfig={unsavedConfig as APIConfig} config={config as APIConfig} onChange={setUnsavedConfig} /> */}
                        <Footer onSaveClick={handleSaveClick} onDismissClick={handleDismissClick} />
                    </div>
                </StyledSettingsPopup>
            </Dialog>
        </div>
    );
};

export default Layout;
